<template>
  <div>
    <div class="wrapper">
      <v-btn
        outlined
        to="/worker/verifications/add"
        sele
        class="mt-4 mb-8"
        color="blue"
        :exact="true"
        :disabled="dateRequires || yesterdayRequires"
      >
        Добавить поверку
      </v-btn>
    </div>

    <div v-if="verifications === null">
      <v-skeleton-loader
        v-for="i in 5"
        :key="i"
        type="article"
      />
    </div>

    <div v-else>
      <div
        v-if="routes.length"
      >
        <h3>
          Маршрутные листы
        </h3>
        <div
          v-for="route in routes"
          :key="route.id"
        >
          <a
            :href="route.map"
            target="_blank"
          >
            {{ route.name }}
          </a>
        </div>
      </div>

      <div>
        <v-btn
          v-if="formattedDate !== $store.state.auth.user.not_a_driver"
          color="primary"
          @click="notDriver"
        >
          Не водитель
        </v-btn>

        <v-row v-if="yesterday.date">
          <v-col cols="12" md="2">
            <v-text-field
              label="Дата"
              :value="yesterday.date"
              disabled
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              :value="yesterday.start"
              label="Пробег на начало смены"
              type="number"
              disabled
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-img
              v-if="yesterday.start_image"
              :src="yesterday.start_image.url"
              max-width="100%"
            />

            <v-img
              v-else-if="yesterday_start_image_uploaded"
              :src="yesterday_start_image_uploaded"
              max-width="100%"
            />

            <v-file-input
              v-if="!yesterday.start_image"
              v-model="yesterday_start_image"
              label="Изображение"
              accept="image/*"
              @change="uploadImage(yesterday_start_image, 'start', false)"
              :disabled="!yesterday.start"
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              v-model="yesterday.finish"
              label="Пробег в конце смены"
              type="number"
              :rules="[
                v => (parseFloat(v) >= yesterday.start) || 'Пробег не может быть меньше начала смены'
              ]"
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-img
              v-if="yesterday.finish_image"
              :src="yesterday.finish_image.url"
              max-width="100%"
            />

            <v-img
              v-else-if="yesterday_finish_image_uploaded"
              :src="yesterday_finish_image_uploaded"
              max-width="100%"
            />

            <v-file-input
              v-if="!yesterday.finish_image"
              v-model="yesterday_finish_image"
              label="Изображение"
              accept="image/*"
              @change="uploadImage(yesterday_finish_image, 'finish', false)"
              :disabled="!yesterday.finish"
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-btn
              :disabled="parseFloat(yesterday.start) > parseFloat(yesterday.finish)"
              @click="saveMileage(yesterday)"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="today.date">
          <v-col cols="12" md="2">
            <v-text-field
              v-model="today.date"
              label="Дата"
              disabled
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              v-model="today.start"
              label="Пробег на начало смены"
              type="number"
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-img
              v-if="today.start_image"
              :src="today.start_image.url"
              max-width="100%"
            />

            <v-img
              v-else-if="today_start_image_uploaded"
              :src="today_start_image_uploaded"
              max-width="100%"
            />

            <v-file-input
              v-if="!today.start_image"
              v-model="today_start_image"
              label="Изображение"
              accept="image/*"
              @change="uploadImage(today_start_image, 'start', true)"
              :disabled="!today.start"
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              label="Пробег в конце смены"
              v-model="today.finish"
              type="number"
              :rules="[
                v => (parseFloat(v) >= today.start) || 'Пробег не может быть меньше начала смены'
              ]"
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-img
              v-if="today.finish_image"
              :src="today.finish_image.url"
              max-width="100%"
            />

            <v-img
              v-else-if="today_finish_image_uploaded"
              :src="today_finish_image_uploaded"
              max-width="100%"
            />

            <v-file-input
              v-if="!today.finish_image"
              v-model="today_finish_image"
              label="Изображение"
              accept="image/*"
              @change="uploadImage(today_finish_image, 'finish', true)"
              :disabled="!today.finish"
            />
          </v-col>

          <v-col cols="12" md="2">
            <v-btn
              :disabled="!today.start_image_id || !today.start || (today.start && parseFloat(today.start) > parseFloat(today.finish))"
              @click="saveMileage(today)"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div
        v-for="(items, date) in verifications"
        :key="date"
        class="mb-10"
        :id="date ? date : 'no-date'"
      >
        <div class="date mb-4 mt-4">
          {{ date | date }}
        </div>

        <v-card
          v-for="item in items"
          :key="item.id"
          class="item mb-4" :color="getColor(item.meter_status_id)"
          :to="`/worker/verifications/edit/${item.id}`"
          :disabled="dateRequires || yesterdayRequires"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col sm="6">
                <p>{{ item.address }}</p>
                <p>
                  <a
                    :href="`tel:${item.phone}`"
                    onclick="event.stopPropagation()"
                  >
                    {{ item.phone }}
                  </a>
                </p>
                <p
                  v-if="item.phone_1"
                  onclick="event.stopPropagation()"
                >
                  <a :href="`tel:${item.phone_1}`">
                    {{ item.phone_1 }}
                  </a>
                </p>
                <p
                  v-if="item.phone_2"
                  onclick="event.stopPropagation()"
                >
                  <a :href="`tel:${item.phone_2}`">
                    {{ item.phone_2 }}
                  </a>
                </p>

                <p
                  v-if="item.lat"
                >
                  <a
                    :href="`yandexmaps://maps.yandex.ru/?ll=${item.lng},${item.lat}&z=12&l=map&pt=${item.lng},${item.lat}`"
                    target="_blank"
                    onclick="event.stopPropagation()"
                  >
                    Открыть в "Яндекс картах"
                  </a>
                </p>
              </v-col>

              <v-col sm="6" align="right">
                <p>
                  <v-btn
                    :href="`/worker/verifications/edit/${item.id}`"
                    @click.stop="e => e.stopPropagation()"
                    target="_blank"
                    icon
                    :disabled="dateRequires || yesterdayRequires"
                  >
                    <v-icon>
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>

                  <v-icon
                    v-if="item.images.length > 0"
                    class="d-inline-block mr-2"
                  >
                    mdi-camera
                  </v-icon>
                  <strong>{{ item.job_type_id === 1 ? 'Поверка' : 'Замена' }}, {{ item.type_id === 1 ? 'Газ' : 'Вода' }}
                    <span v-if="item.type_id === 1" class="ml-4">{{ item.gas_meter ? item.gas_meter.brand : '' }}</span>
                  </strong>
                </p>
                <p>
                  <small>
                    <strong>{{ item.note }}</strong>
                  </small>
                </p>

                <v-btn
                  v-if="item.meter_status_id !== 3 && $store.state.auth.user.id === item.manager_id"
                  color="error"
                  outlined
                  @click.prevent="remove(item.id)"
                >
                  Удалить
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-pagination
      v-model="page"
      :length="pages"
    />

    <DeleteConfirmation
      :visible="deleteConfirmationVisible"
      v-on:confirm="removeConfirmed"
      v-on:decline="removeDeclined"
    />
  </div>
</template>

<script>
import { MileageService, VerificationsService, RoutesService, ImageService, UsersService } from '@/common/api.service'
import DeleteConfirmation from '@/components/Modals/DeleteConfirmation'

export default {
  components: {
    DeleteConfirmation
  },

  data () {
    return {
      verifications: null,
      page: 1,
      pages: 0,
      loading: false,
      deleteConfirmationVisible: false,
      deleteVerificationId: null,
      routes: [],
      mileages: [],
      today: {},
      yesterday: {},
      today_start_image: null,
      today_start_image_uploaded: null,
      today_finish_image: null,
      today_finish_image_uploaded: null,
      yesterday_start_image: null,
      yesterday_start_image_uploaded: null,
      yesterday_finish_image: null,
      yesterday_finish_image_uploaded: null,
      dateRequires: false,
      yesterdayRequires: false
    }
  },

  computed: {
    // Сегодняшняя дата в формате 2000-06-12
    formattedDate () {
      const date = new Date()

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      if (day < 10) {
        day = `0${day}`
      }

      if (month < 10) {
        month = `0${month}`
      }

      return `${year}-${month}-${day}`
    }
  },

  methods: {
    getColor(status) {
      const colors = {
        2: '#DAFFD3',
        3: '#D3DDFF',
        4: '#D3D3D3',
      }

      return colors[status]
    },

    // Нажата кнопка 'удалить'
    // Сохраняем ID и показываем предупреждение
    remove(id) {
      this.deleteVerificationId = id
      this.deleteConfirmationVisible = true
    },

    // Юзер подтвердил удаление
    removeConfirmed() {
      this.deleteConfirmationVisible = false

      VerificationsService.delete(this.deleteVerificationId).then(() => {
        /*
      VerificationsService
          .getByDates()
          .then(({data}) => {
            this.verifications = data.verifications
          })
         */

        VerificationsService
          .getByDatesForWorker(this.page)
          .then(({ data }) => {
            this.verifications = data.verifications
            this.pages = data.pages

            if (this.$route.query.date) {
              setTimeout(() => {
                const el = document.getElementById(this.$route.query.date)
                if (el) {
                  el.scrollIntoView({ behavior: 'smooth' })
                }
              }, 1000)
            }
          })
      })
    },

    // Юзер передумал удалять
    removeDeclined() {
      this.deleteConfirmationVisible = false
    },

    // Сохранить пробег
    saveMileage (mileage) {
      MileageService
        .add(mileage)
        .then(() => {
          this.fetchMileages()
        })
    },

    uploadImage (image, type, today) {
      if (!image) {
        return
      }

      const formData = new FormData()
      formData.append('image', image)
      formData.append('type', type)

      return ImageService.upload(formData)
        .then((data) => {
          if (today) {
            if (type === 'start') {
              this.today_start_image = null
              this.today_start_image_uploaded = data.url
              this.today.start_image_id = data.id
            } else {
              this.today_finish_image = null
              this.today_finish_image_uploaded = data.url
              this.today.finish_image_id = data.id
            }
          } else {
            if (type === 'start') {
              this.yesterday_start_image = null
              this.yesterday_start_image_uploaded = data.url
              this.yesterday.start_image_id = data.id
            } else {
              this.yesterday_finish_image = null
              this.yesterday_finish_image_uploaded = data.url
              this.yesterday.finish_image_id = data.id
            }
          }
        })
        .catch(() => {
          this.$notification('Ошибка сохранения')
        })
        .finally(() => {
          this.loading = false
        })
    },

    fetchMileages () {
      MileageService
        .get()
        .then((response) => {
          this.mileages = response.data

          const today = this.mileages.find(mileage => mileage.date === this.formattedDate)
          this.dateRequires = !today
          this.today = today ? today : {
            date: this.formattedDate,
            start: '',
            end: ''
          }

          const yesterday = today ? this.mileages[1] : this.mileages[0]

          if (yesterday && (!yesterday.finish || !yesterday.finish_image_id)) {
            this.yesterday = yesterday
            this.yesterdayRequires = true
          } else {
            this.yesterday = {}
            this.yesterdayRequires = false
          }
        })
    },

    // Отметить пользователя не-водителем на сегодня
    notDriver () {
      UsersService
        .not_a_driver()
        .then(() => {
          this.today = {}
          this.dateRequires = false
          this.yesterday = {}
          this.yesterdayRequires = false
          this.mileages = []
        })
    }
  },

  mounted () {
    /*
    VerificationsService
        .getByDates()
        .then(({data}) => {
          this.verifications = data.verifications

          if (this.$route.query.date) {
            setTimeout(() => {
              const el = document.getElementById(this.$route.query.date)
              if (el) {
                el.scrollIntoView({ behavior: 'smooth' })
              }
            }, 1000)
          }
        })
    */

    VerificationsService
      .getByDatesForWorker(1)
      .then(({ data }) => {
        this.verifications = data.verifications
        this.pages = data.pages

        if (this.$route.query.date) {
          setTimeout(() => {
            const el = document.getElementById(this.$route.query.date)
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' })
            }
          }, 1000)
        }
      })

    RoutesService
      .get()
      .then((response) => {
        this.routes = response.routes
      })

    if (this.$store.state.auth.user.not_a_driver) {
      if (this.formattedDate !== this.$store.state.auth.user.not_a_driver) {
        this.fetchMileages()
      }
    } else {
      this.fetchMileages()
    }
  },

  watch: {
    page: {
      handler (val) {
        VerificationsService
          .getByDatesForWorker(val)
          .then(({ data }) => {
            this.verifications = data.verifications
            this.pages = data.pages
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  p:last-child {
    margin-bottom: 0;
  }
}

.wrapper {
  text-align: center;
}
</style>
